jQuery( document ).ready( function( $ ) {

    if (document.cookie.indexOf("uk_disclaimer=") < 0) {
        $('.disclaimer-overlay').removeClass('d-none').addClass('d-block');
    } else {
        // DO NOTHING
    }

    $('.disclaimer-accept').on('click', function() {

        // expires in 9999
        // document.cookie = "uk_disclaimer=true; SameSite=None; Secure; expires=Fri, 31 Dec 9999 23:59:59 GMT";

        expiry = new Date();
        expiry.setTime(expiry.getTime()+3600000*24*14); // Two weeks
        
        // Date()'s toGMTSting() method will format the date correctly for a cookie
        document.cookie = "uk_disclaimer=true; SameSite=None; Secure; expires=" + expiry.toGMTString();

        $('.disclaimer-overlay').removeClass('d-block').addClass('d-none');

    })
})